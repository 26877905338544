import Mmenu from "mmenu-js";
import 'mmenu-js/src/mmenu.scss'
import '../../Scss/nav/_mmenu.scss'

const MobileMenu = () => {
    const topbar = document.querySelector('#nav-offcanvas').innerHTML;
    const searchbar = document.querySelector('#nav-main .searchbar').innerHTML;
    const menu = new Mmenu('#MobileMenuWrap', {
        extensions: ["position-right", "pagedim"],
        "navbars": [
            {
                "position": "top",
                "content": [
                    "prev",
                    "title"
                ]
            },
            {
                "position": "top",
                "content": [
                    '<div class="searchbar">' + searchbar + '</div>'
                ]
            },

            {
                "position": "bottom",
                "content": [
                   topbar
                ]
            }
        ]
    });
    const api = menu.API;
    const hamBurger = document.querySelector('.menu-toggle');
    const menuWrap = document.querySelector('#MobileMenuWrap');
    menuWrap.classList.remove('uk-hidden');
    hamBurger.addEventListener('click', evnt => {
        evnt.preventDefault();
        api.open();
    })
};

export {MobileMenu};
