const DataTableInit = () => {
    if ($().dataTable) {

        /* API method to get paging information */
        $.fn.dataTableExt.oApi.fnPagingInfo = function (oSettings) {
            return {
                "iStart": oSettings._iDisplayStart,
                "iEnd": oSettings.fnDisplayEnd(),
                "iLength": oSettings._iDisplayLength,
                "iTotal": oSettings.fnRecordsTotal(),
                "iFilteredTotal": oSettings.fnRecordsDisplay(),
                // eslint-disable-next-line
                "iPage": oSettings._iDisplayLength === -1
                    ? 0 : Math.ceil(oSettings._iDisplayStart / oSettings._iDisplayLength),
                // eslint-disable-next-line
                "iTotalPages": oSettings._iDisplayLength === -1
                    ? 0 : Math.ceil(oSettings.fnRecordsDisplay() / oSettings._iDisplayLength)
            };
        }

        $.fn.dataTable.moment('DD MMM YYYY');

        /* Bootstrap style pagination control */
        $.extend($.fn.dataTableExt.oPagination, {
            "bootstrap": {
                "fnInit": function (oSettings, nPaging, fnDraw) {
                    var oLang = oSettings.oLanguage.oPaginate;
                    var fnClickHandler = function (e) {
                        e.preventDefault();
                        if (oSettings.oApi._fnPageChange(oSettings, e.data.action)) {
                            fnDraw(oSettings);
                        }
                    };

                    $(nPaging).addClass('pagination').append(
                        '<ul class="pagination">' +
                        '<li class="prev disabled"><a href="#">&larr; ' + oLang.sPrevious + '</a></li>' +
                        '<li class="next disabled"><a href="#">' + oLang.sNext + ' &rarr; </a></li>' +
                        '</ul>'
                    );
                    var els = $('a', nPaging);
                    $(els[0]).bind('click.DT', {action: "previous"}, fnClickHandler);
                    $(els[1]).bind('click.DT', {action: "next"}, fnClickHandler);
                },

                "fnUpdate": function (oSettings, fnDraw) {
                    var iListLength = 5;
                    var oPaging = oSettings.oInstance.fnPagingInfo();
                    var an = oSettings.aanFeatures.p;
                    var i, j, sClass, iStart, iEnd, iHalf = Math.floor(iListLength / 2);

                    if (oPaging.iTotalPages < iListLength) {
                        iStart = 1;
                        iEnd = oPaging.iTotalPages;
                    } else if (oPaging.iPage <= iHalf) {
                        iStart = 1;
                        iEnd = iListLength;
                    } else if (oPaging.iPage >= (oPaging.iTotalPages - iHalf)) {
                        iStart = oPaging.iTotalPages - iListLength + 1;
                        iEnd = oPaging.iTotalPages;
                    } else {
                        iStart = oPaging.iPage - iHalf + 1;
                        iEnd = iStart + iListLength - 1;
                    }

                    for (i = 0, iLen = an.length; i < iLen; i++) {
                        // Remove the middle elements
                        $('li:gt(0)', an[i]).filter(':not(:last)').remove();

                        // Add the new list items and their event handlers
                        for (j = iStart; j <= iEnd; j++) {
                            sClass = (j == oPaging.iPage + 1) ? 'class="active"' : '';
                            $('<li ' + sClass + '><a href="#">' + j + '</a></li>')
                                .insertBefore($('li:last', an[i])[0])
                                // eslint-disable-next-line
                                .bind('click', function (e) {
                                    e.preventDefault();
                                    oSettings._iDisplayStart = (parseInt($('a', this).text(), 10) - 1) * oPaging.iLength;
                                    fnDraw(oSettings);
                                });
                        }

                        // Add / remove disabled classes from the static elements
                        if (oPaging.iPage === 0) {
                            $('li:first', an[i]).addClass('disabled');
                        } else {
                            $('li:first', an[i]).removeClass('disabled');
                        }

                        if (oPaging.iPage === oPaging.iTotalPages - 1 || oPaging.iTotalPages === 0) {
                            $('li:last', an[i]).addClass('disabled');
                        } else {
                            $('li:last', an[i]).removeClass('disabled');
                        }
                    }
                }
            }
        });

        $.fn.dataTable.moment('DD.MM.YYYY');
        // $.fn.dataTable.moment( 'DD. MMM YYYY' );

        $('.data-table').dataTable({
            "bPaginate": false,
            "bLengthChange": false,
            "bFilter": false,
            "bSort": true,
            "bInfo": false,
            "bAutoWidth": false,
            "bStateSave": false,
            "aaSorting": [[0, 'desc']]
        });
        $('.data-table-asc').dataTable({
            "bPaginate": false,
            "bLengthChange": false,
            "bFilter": false,
            "bSort": true,
            "bInfo": false,
            "bAutoWidth": false,
            "bStateSave": false,
            "aaSorting": [[0, 'asc']],
            "aoColumns": [
                {"sWidth": "50%", "sType": "html"},
                null
            ]
        });
        $('.data-table-asc-3col').dataTable({
            "bPaginate": false,
            "bLengthChange": false,
            "bFilter": false,
            "bSort": true,
            "bInfo": false,
            "bAutoWidth": false,
            "bStateSave": false,
            "aaSorting": [[0, 'asc']],
            "aoColumns": [
                {"sWidth": "30%", "sType": "html"},
                {"sWidth": "40%", "sType": "html"},
                {"sWidth": "30%", "sType": "html"}
            ]
        });
        $('.data-table-full').dataTable({
            "bPaginate": true,
            "lengthChange": 25,
            "pageLength": 25,
            "pagingType": "bootstrap",
            "searching": true,
            "ordering": true,
            "bInfo": false,
            "bAutoWidth": false,
            "bStateSave": false,
            "aaSorting": [[0, 'desc']]
        });
        $('.data-table-nosearch').dataTable({
            "bPaginate": false,
            "bLengthChange": false,
            "bFilter": false,
            "bSort": true,
            "bInfo": false,
            "bAutoWidth": false,
            "bStateSave": false,
            "aaSorting": [[0, 'desc']]
        });
        $('.data-table-pc').dataTable({
            "bPaginate": false,
            "bLengthChange": false,
            "bFilter": false,
            "bSort": true,
            "bInfo": false,
            "bAutoWidth": false,
            "bStateSave": false,
            "aaSorting": [[2, 'desc']]
        });

        $('.data-table-event').dataTable({
            "lengthChange": false,
            "searching": false,
            "ordering": true,
            "info": false,
            "autoWidth": false,
            "stateSave": false,
            "paging": false,
            "order": [[0, 'asc']],
            "columnDefs": [
                {"targets": 0}
            ]
        });

        $('.data-table-event-archive').dataTable({
            "lengthChange": false,
            "searching": false,
            "ordering": true,
            "info": false,
            "autoWidth": false,
            "stateSave": false,
            "paging": true,
            "pageLength": 25,
            "order": [[0, 'desc']],
            "columnDefs": [
                {"targets": 0}
            ],
            dom: "<'row'<'col-sm-6'l><'col-sm-6'f>><'row'<'col-sm-12'tr>><'row'<'col-xs-12'i><'col-xs-12'p>>"
        });
        $('.data-table-event-short').dataTable({
            "lengthChange": false,
            "searching": false,
            "ordering": true,
            "info": false,
            "autoWidth": false,
            "stateSave": false,
            "paging": true,
            "pageLength": 6,
            "order": [[0, 'desc']],
            "columnDefs": [
                {"targets": 0}
            ],
            dom: "<'row'<'col-sm-6'l><'col-sm-6'f>><'row'<'col-sm-12'tr>><'row'<'col-xs-12'i><'col-xs-12'p>>"
        });
        $('.data-table-event-nopaging-desc').dataTable({
            "lengthChange": false,
            "searching": false,
            "ordering": true,
            "info": false,
            "autoWidth": false,
            "stateSave": false,
            "paging": false,
            "pageLength": 25,
            "order": [[0, 'desc']],
            "columnDefs": [
                {"targets": 0}
            ],
            dom: "<'row'<'col-sm-6'l><'col-sm-6'f>><'row'<'col-sm-12'tr>><'row'<'col-xs-12'i><'col-xs-12'p>>"
        });


        $('.data-table-funds').dataTable({
            "bPaginate": false,
            "bLengthChange": false,
            "bFilter": false,
            "bSort": true,
            "bInfo": false,
            "bAutoWidth": false,
            "bStateSave": false,
            "aaSorting": [[0, 'desc']],
            "aoColumns": [null, null, null, null, {"sType": "numeric"}]
        });
        $('.data-table-funding').dataTable({
            "bPaginate": true,
            "bLengthChange": true,
            "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
            "bFilter": false,
            "bSort": true,
            "bInfo": false,
            "bAutoWidth": false,
            "bStateSave": false,
            "aaSorting": [[1, 'asc']],
            dom: "<'row'<'col-sm-6'><'col-sm-6'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-6'il><'col-sm-6'p>>"

        });
        $('.data-table-published').dataTable({
            "bPaginate": false,
            "bLengthChange": false,
            "bFilter": false,
            "bSort": true,
            "bInfo": false,
            "bAutoWidth": false,
            "bStateSave": false,
            "aaSorting": [[3, 'desc']]
        });
    }
}

export {DataTableInit};
