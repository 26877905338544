import countdown from 'countdown';

const countdowndate = document.querySelector("#countDown");

const year = Number(countdowndate.dataset.year);
const month = Number(countdowndate.dataset.month) - 1;
const day = Number(countdowndate.dataset.day);
const hour = Number(countdowndate.dataset.hour);
const minute = Number(countdowndate.dataset.minute);

const lang = document.querySelector("html").getAttribute("lang");

const mycountdown = () => {
    countdown(
        new Date(year,month,day, hour, minute),
        function(ts) {
            if (lang=='en-US') {
                countdown.setLabels(
                    '<span>millisecond</span>|<span>second</span>|<span>minute</span>|<span>hour</span>|<span>day</span>|<span>week</span>|<span>month</span>|<span>year</span>|<span>decade</span>|<span>century</span>|<span>millennium</span>',
                    ' <span>milliseconds</span>|<span>seconds</span>|<span>minutes</span>|<span>hours</span>|<span>days</span>|<span>weeks</span>|<span>months</span>|<span>years</span>|<span>decades</span>|<span>centuries</span>|<span>millennia</span>',
                    '',
                    '',
                    'now'
                    );
            } else {
                countdown.setLabels(
                    '<span>millisecond</span>|<span>Sekunde</span>|<span>Minute</span>|<span>Stunde</span>|<span>Tag</span>|<span>Woche</span>|<span>Monat</span>|<span>Jahr</span>|<span>Dekade</span>|<span>Jahrhundert</span>|<span>Jahrtausend</span>',
                    ' <span>milliseconds</span>|<span>Sekunden</span>|<span>Minuten</span>|<span>Stunden</span>|<span>Tage</span>|<span>Wochen</span>|<span>Monate</span>|<span>Jahre</span>|<span>Dekaden</span>|<span>Jahrhunderte</span>|<span>Jahrtausende</span>',
                    '',
                    '',
                    'now'
                );
            }
            if (!ts.seconds || ts.value > 0) {
                ts.seconds='00';
            }
            if (!ts.minutes || ts.value > 0) {
                ts.minutes='00';
            }
            if (!ts.hours || ts.value > 0) {
                ts.hours='00';
            }
            if (!ts.days || ts.value > 0) {
                ts.days='0';
            }
            if (ts.seconds < 10 && ts.seconds > 0) {
                ts.seconds='0'+ts.seconds;
            }

            document.getElementById('countDown').innerHTML = ts.toHTML("div", "section");
        },
        countdown.DAYS|countdown.HOURS|countdown.MINUTES|countdown.SECONDS,
        4,
        0,
        );
}

export {mycountdown};

