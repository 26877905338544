let scrollDown = false;
let scrollUp = false;

const detectScrollDir = () => {
    let position = $(window).scrollTop();
    document.addEventListener('scroll', function () {
        const scroll = $(window).scrollTop();
        if (scroll > position) {
            scrollUp = false;
            scrollDown = true;
            $('body').removeClass('scrollingUp').addClass('scrollingDown');
        } else {
            scrollUp = true;
            scrollDown = false;
            $('body').removeClass('scrollingDown').addClass('scrollingUp');
        }
        if (scroll <= 10) {
            scrollUp = false;
            scrollDown = false;
            $('body').removeClass('scrollingUp').removeClass('scrollingDown').addClass('atTop').removeClass('hideFlyout');
        } else {
            $('body').removeClass('atTop');
        }
        position = scroll;
        const scrollHeight = $(document).height();
        const scrollPosition = $(window).height() + $(window).scrollTop();
        const distToBottom = (scrollHeight - scrollPosition);
        if (distToBottom / scrollHeight === 0) {
            $('body').addClass('atBottom reachedBottom');
        } else {
            $('body').removeClass('atBottom');
        }
    });
}

export {detectScrollDir,scrollDown,scrollUp};
