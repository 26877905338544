import '@babel/polyfill';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import {detectScrollDir, scrollDown, scrollUp} from './modules/detectScroll'

UIkit.use(Icons);
import(/* webpackChunkName: "mmmenu" */'./modules/mmenu').then(module => {
    module.MobileMenu();
});

if ($("[id^=countDown]").length) {
    import(/* webpackChunkName: "countdown" */'./modules/countdown').then(module => {
        module.mycountdown();
    });
}

if ($('[class^=data-table]').length || $("[id^=data-table]").length || $("table").length) {
    import(/* webpackChunkName: "dataTableInit" */'./modules/dataTable').then(module => {
        module.DataTableInit();
    })
}

function doubleClick() {
    var $el = $('#nav-main ul.uk-navbar-dropdown-nav>li.sub');
    $el.on('touchstart', function () {
        $(this).toggleClass('touched');
        $el.not(this).removeClass('touched');
    });
}

function header() {
    var $stickyHeader = $('.stickyHeader');
    var sticky = $stickyHeader.offset().top;
    document.addEventListener('scroll', function () {
        if (window.pageYOffset > sticky * 3) {
            $stickyHeader.addClass('fixed');
        } else {
            $stickyHeader.removeClass('fixed');
        }
        if ((window.pageYOffset < sticky * 4) && (scrollUp === true)) {
            $stickyHeader.addClass('hideSticky');
        } else {
            $stickyHeader.removeClass('hideSticky');
        }
        if ((window.pageYOffset > sticky * 4) && (scrollDown === true)) {
            $stickyHeader.addClass('sc_fadeout');
        } else {
            $stickyHeader.removeClass('sc_fadeout');
        }
    });
}

function sliderMobile() {
    UIkit.slider('.boxslider', {
        center: true,
    });
}

function sliderDesktop() {
    UIkit.slider('.boxslider', {
        center: false,
    });
}

function removeEmptyParagraph() {
    $('main p').each(function () {
        var $this = $(this);
        if ($this.html().match(/^\s*&nbsp;\s*$/)) $this.remove();
    });
}

function ddMenu() {
    $(document).on('mouseenter click', '#nav-main ul.nav > li.sub:not(".init")', function () {
        $(this).addClass('init');
        var $nav = $(this).find('li.sub ul.lvl-2');
        var maxCount = 0;
        $nav.each(function () {
            var $navLi = $(this).children($('li'));
            var $navHeight = ($navLi.length);
            if ($navHeight > maxCount) {
                maxCount = $navHeight;
            }
        });
        $(this).find('ul.uk-nav').css('min-height', (maxCount * 2.75) + 'rem');
    });
}

function boxSquare() {
    var $box = $('.box-square');
    setTimeout(function () {
        $box.each(function () {
            var boxWidth;
            boxWidth = $(this).width();
            $(this).css('min-height', boxWidth);
        });
    }, 500);
}


function burger() {
    $('.menu-toggle').on('click', function () {
        $(this).toggleClass('active');
    });
}

function $bp_mobile_s() {
    if ($('#wrapper-outer').width() < 768) {
        sliderMobile();
    } else {
        sliderDesktop();
    }
}

function afterResize() {
    let resizeId;
    let windowWidth = $(window).width();
    window.addEventListener('resize', function () {
        if ($(window).width() != windowWidth) {
            windowWidth = $(window).width();
            $('body').addClass('resizing');
            clearTimeout(resizeId);
            resizeId = setTimeout(doneResizing, 500);
        }
    });

    function doneResizing() {
        $('body').removeClass('resizing');
        header();
        $bp_mobile_s();
        boxSquare();
    }
}

function detectBrowser() {
    if (navigator.userAgent.match(/msie/i) || navigator.userAgent.match(/trident/i)) {
        $("html").addClass("ie");
    } else{
        detectScrollDir();
        header();
        ddMenu();
        burger();
        afterResize();
    }
}

function init() {
    detectBrowser();
    $bp_mobile_s();
    afterResize();
    doubleClick();
    boxSquare();
    removeEmptyParagraph();
}

$(document).ready(function () {
    init();
});
